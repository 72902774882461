"use client";
// Core
import cx from "classnames";

// Utils
import st from "./styles.module.css";
import { getFormatIconName } from "./utils";

// Assets
import { default as iconSpriteStaticImg } from "./assets/sprite.svg";
import * as IconsAll from "./assets/icons";

export type IconProps = {
  name: string;
  rotate?: boolean;
  className?: string;
  size?:
    | "10"
    | "12"
    | "14"
    | "16"
    | "18"
    | "24"
    | "28"
    | "32"
    | "40"
    | "48"
    | "full-width"
    | "inherit";
  testId?: string;
  onClick?: () => void;
  gutter?: "mr-mini" | "mr-small";
};

const iconSprite = iconSpriteStaticImg as { src: string; width: number; height: number };
const iconSpriteUrl = iconSprite.src;

export const Icon = (props: IconProps) => {
  const { name, size, rotate, testId, gutter, className, ...otherProps } = props;
  const iconStyle = cx(
    "anticon",
    st.icon,
    { [st.rotate]: rotate },
    size && { [st[`size-${size}`]]: size },
    gutter && { [st[gutter]]: gutter },
    className,
  );

  const hasName = name in IconsAll;

  if (!hasName) {
    // eslint-disable-next-line no-console
    console.warn(`The icon ${name} does not exist in the list`);
    return null;
  }
  const iconFileName = getFormatIconName(name);

  return (
    <span className={iconStyle} role="img" data-testid={testId} {...otherProps}>
      <svg width="1em" height="1em" fill="currentColor">
        <use xlinkHref={`${iconSpriteUrl}#${iconFileName}`} />
      </svg>
    </span>
  );
};
